<!-- Footer Section -->
<footer class="bg-gray-900 py-4 px-4 mx-0 mt-3 lg:px-8">
  <div class="grid text-center md:text-left text-white">
    <div class="grid col-12 lg:col-6">
      <div class="col-12 md:col-6">
        <a [routerLink]="'/'"
          class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3 cursor-pointer">
          <img src="images/logo-white.png" alt="Footer Logo" class="w-3rem h-2rem mr-2">
          <span class="font-bold text-3xl text-white">SendB2B</span>
        </a>
      </div>
      <div class="col-12 md:col-6">
        01 Data Ltd t/a SendB2B, 124 City Road, London, EC1V 2NX
        <div class="mt-2">
          <em class="pi pi-inbox border-round"></em>
          hello&#64;sendb2b.co.uk
        </div>
      </div>
    </div>
    <div class="grid col-12 lg:col-6">
      <div class="col-12 md:col-6">
        <h4 class="font-medium text-2xl line-height-3 mb-3 text-900 text-white">Company</h4>
        <a class="line-height-3 text-xl block m-1" [routerLink]="'/about'">
          About Us
        </a>
        <a class="line-height-3 text-xl block m-1" [routerLink]="'/knowledge-base'">
          News
        </a>
        <a class="line-height-3 text-xl block m-1" [routerLink]="'/careers'">
          Careers
        </a>
      </div>
      <div class="col-12 md:col-6">
        <h4 class="font-medium text-2xl line-height-3 mb-3 text-900 text-white">Resources</h4>
        <a class="line-height-3 text-xl block m-1" [routerLink]="'/terms-conditions'">
          Terms & Conditions
        </a>
        <a class="line-height-3 text-xl block m-1" [routerLink]="'/privacy-policy'">
          Privacy Policy
        </a>
        <a class="line-height-3 text-xl block m-1" [routerLink]="'/cookie-policy'">
          Cookie Policy
        </a>
      </div>
    </div>
  </div>
</footer>