<div class="container">
  <h1>Cookie Policy</h1>
  <p>Last updated: 02 July 2024</p>

  <h2>Introduction</h2>
  <p>This Cookie Policy explains how SendB2B ("we", "us", or "our") uses cookies and similar technologies to recognize you when you visit our website at sendb2b.co.uk.</p>

  <h2>What are cookies?</h2>
  <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>

  <h2>Why do we use cookies?</h2>
  <p>We use cookies to improve your experience on our site, including keeping you logged in, understanding how you use our site, and personalizing content and advertisements.</p>

  <h2>What types of cookies do we use?</h2>
  <ul>
    <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function and cannot be switched off in our systems.</li>
    <li><strong>Performance Cookies:</strong> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.</li>
    <li><strong>Functionality Cookies:</strong> These cookies allow the website to provide enhanced functionality and personalization.</li>
    <li><strong>Targeting Cookies:</strong> These cookies are used to deliver advertisements that are more relevant to you and your interests.</li>
  </ul>

  <h2>How can you control cookies?</h2>
  <p>You can control and/or delete cookies as you wish - for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.</p>

  <h2>Changes to this Cookie Policy</h2>
  <p>We may update this Cookie Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.</p>

  <h2>Contact Us</h2>
  <p>If you have any questions about our use of cookies or other technologies, please email us at hello&#64;sendb2b.co.uk.</p>
</div>
