// Import modules
import { Component, OnInit } from "@angular/core";
// Import constants
import { apiEndpoint, httpMethod, PRICE_LIST } from "src/app/lib/constants";
// Import services
import { APIService } from "src/app/services/api.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  providers: []
})
export class PricingComponent implements OnInit {
  public PRICE_LIST: any = PRICE_LIST;

  constructor(public utilService: UtilService, private apiService: APIService) { }

  ngOnInit(): void {
  }

  createPaymentLink(priceType: any) {
    this.utilService.showSpinner();
    this.apiService.httpRequest(httpMethod.POST, apiEndpoint.CREATE_PAYMENT_LINK, {
      type: priceType.name
    }, true).then((res: any) => {
      if (res.data?.paymentLinkUrl && !res.response_error)
        window.open(res.data.paymentLinkUrl, "_blank");

      this.utilService.hideSpinner();
    }).catch((error: any) => {
      this.utilService.handleErrorAndHideSpinner(error);
    });
  }
}