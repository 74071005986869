<!-- Notification Section -->
<div
  class="card mx-auto col-11 md:col-10 lg:col-8 xl:col-6 mt-5 surface-card p-4 shadow-2 border-round w-full lg:w-5 md:w-6">
  <div class="flex align-items-center justify-content-between mb-4">
    <h5>Notifications</h5>
  </div>
  <div class="align-items-center py-2 border-bottom-1 surface-border" *ngIf="notifications?.length === 0">
    <h6 class="line-height-3">No Notification</h6>
  </div>
  <div class="flex align-items-center py-2 border-bottom-1 surface-border" *ngFor="let notification of notifications">
    <div
      class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
      <i class="pi pi-send text-xl text-blue-500" *ngIf="notification.title === 'Mail Sent'"></i>
      <i class="pi pi-minus-circle text-xl text-blue-500" *ngIf="notification.title === 'No Remaining Credits'"></i>
      <i class="pi pi-money-bill text-xl text-blue-500" *ngIf="notification.title === 'Payment Succeed'"></i>
      <i class="pi pi-cart-minus text-xl text-blue-500" *ngIf="notification.title.includes('Unsubscribed')"></i>
      <i class="pi pi-cart-plus text-xl text-blue-500" *ngIf="notification.title.includes('Subscribed')"></i>
    </div>
    <span class="text-900 line-height-3">
      {{notification.createdAt | date: "MM-dd-yyyy HH:mm" }}:
      <span class="text-700">{{notification.message}}</span>
    </span>
  </div>
</div>