<p-toast></p-toast>

<!-- Header Section -->
<header class="bg-gray-900 px-4 md:px-8 flex align-items-center justify-content-between relative lg:static"
  style="min-height: 80px" #home id="home">
  <div class="flex gap-2 align-items-center mr-0 lg:mr-6 select-none">
    <a [routerLink]="'/'">
      <img src="images/logo.png" alt="Logo" width="100" />
    </a>
  </div>
  <a pRipple class="block lg:hidden text-white" pStyleClass="@next" enterFromClass="hidden" leaveToClass="hidden"
    [hideOnOutsideClick]="true">
    <i class="pi pi-bars text-4xl"></i>
  </a>
  <div
    class="header-menu align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none md:bg-transparent">
    <ul class="bg-gray-900 list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
      <li class="mx-auto lg:relative">
        <a pRipple [routerLink]="'/area-map'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-map-marker"></i> Area Map
        </a>
      </li>
      <li class="mx-auto">
        <a pRipple [routerLink]="'/pricing'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-money-bill"></i> Pricing
        </a>
      </li>
      <li class="mx-auto">
        <a pRipple [routerLink]="'/postcodes'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-warehouse"></i> Areas
        </a>
      </li>
      <li class="mx-auto">
        <a pRipple [routerLink]="'/sectors'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-map"></i> Sectors
        </a>
      </li>
      <li class="mx-auto">
        <a pRipple [routerLink]="'/support'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-address-book"></i> Support
        </a>
      </li>
      <li class="mx-auto" *ngIf="authToken">
        <a pRipple [routerLink]="'/letter-assistant'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-envelope"></i> Letter Assistant
        </a>
        <a pRipple [routerLink]="'/notifications'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-bell"></i> Notification
        </a>
      </li>
      <li class="mx-auto" *ngIf="authToken">
        <a pRipple [routerLink]="'/post-letter-history'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-history"></i> Letter History
        </a>
      </li>
      <li class="mx-auto" *ngIf="authToken">
        <a pRipple [routerLink]="'/profile'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-user"></i> Profile
        </a>
      </li>
      <li class="mx-auto" *ngIf="authToken">
        <a pRipple (click)="utilService.logout(); getToken()" pStyleClass=".header-menu" enterFromClass="hidden"
          enterActiveClass="px-fadein" leaveToClass="hidden" class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-sign-out"></i> Logout
        </a>
      </li>
      <li class="mx-auto" *ngIf="!authToken">
        <a pRipple [routerLink]="'/auth/login'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-sign-in"></i> Login
        </a>
      </li>
      <li class="mx-auto" *ngIf="!authToken">
        <a pRipple [routerLink]="'/auth/register'" [routerLinkActive]="['active']" pStyleClass=".header-menu"
          enterFromClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
          class="flex hover:text-900 hover:surface-100 gap-2">
          <i class="pi pi-user-plus"></i> Register
        </a>
      </li>
    </ul>
  </div>
</header>

<router-outlet></router-outlet>
<app-footer></app-footer>