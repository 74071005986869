const LOCAL_STORAGE: any = {
    userToken: "userToken"
};

const PASSWORD_MINIMUM_LENGTH: number = 8;

const VALIDATION_PATTERN: any = {
    POSTCODE: '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
    PASSWORD: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
}

const EMAIL_BLACKLIST: any[] = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com'];

const SUBSCRIPTION_TYPE = {
    POSTCODE: 'postcode',
    SECTOR: 'sector'
}

const PRICE_LIST: any = {
    TopUp: {
        name: "TopUp",
        amount: 10
    },
    Free: {
        name: "Free",
        areas: 1,
        sectors: 0
    },
    Bronze: {
        name: "Bronze",
        days: 30,
        amount: 20,
        areas: 5,
        sectors: 0
    },
    Silver: {
        name: "Silver",
        days: 30,
        amount: 100,
        areas: 20,
        sectors: 1
    },
    Gold: {
        name: "Gold",
        days: 30,
        amount: 200,
        areas: 50,
        sectors: 5
    },
    Platinum: {
        name: "Platinum",
        days: 30,
        amount: 500,
        areas: 'Unlimited',
        sectors: 'Unlimited'
    }
};

const WARNING_MESSAGE = {
    LOGIN_FIRST: 'Please sign-in first!'
};

const NOTIFICATION_TYPE = {
    WARNING: "warning",
    ERROR: "error",
    SUCCESS: 'success'
};

const DEFAULT_VALUES: any = {
    CURRENCY: 'GBP',
    COUNTRY: 'GB'
}

const URL_VALIDATION_PATTERN: any = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

// API Endpoints
const apiEndpoint = {
    // Auth
    REGISTER: '/auth/register',
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot_password',
    RESET_PASSWORD: '/auth/reset_password',
    VERIFY_PROFILE: '/auth/verify',

    // Profile
    GET_PROFILE_DETAIL: '/profile/get_profile_detail',
    GET_PROFILE_SETTING: '/profile/get_profile_setting',
    UPDATE_PROFILE_SETTING: '/profile/update_profile_setting',
    UPDATE_ADDRESS_DETAIL: '/profile/update_address_detail',
    GET_NOTIFICATION_LIST: '/profile/get_notification_list',

    // User
    INVITE_USER: '/user/invite',
    USER_REGISTER: '/user/register',

    // Document
    GET_DOCUMENT_LIST: '/document/get_document_list',
    UPLOAD_DOCUMENT: '/document/upload_document',
    SET_DEFAULT_DOCUMENT: '/document/set_default_document',
    DELETE_DOCUMENT: '/document/delete_document',
    UPDATE_DOCUMENT_SECTOR: '/document/update_document_sectors',

    // Postcode
    GET_POSTCODE_LIST: '/postcode/get_postcode_list',
    GET_POSTCODES: '/postcode/get_postcodes',
    GET_POSTCODE_DETAIL: '/postcode/get_postcode_detail',
    GET_COMPANY_CHART: '/postcode/get_company_chart',

    // Sector
    GET_SECTOR_LIST: '/sector/get_sector_list',
    GET_SECTORS: '/sector/get_sectors',
    GET_SECTOR_DETAIL: '/sector/get_sector_detail',

    // Subscription
    SUBSCRIBE: '/subscription/subscribe',
    SUBSCRIBE_MULTIPLE: '/subscription/subscribe_multiple',
    UNSUBSCRIBE: '/subscription/unsubscribe',
    UNSUBSCRIBE_MULTIPLE: '/subscription/unsubscribe_multiple',
    UNSUBSCRIBE_ALL: '/subscription/unsubscribe_all',
    GET_SUBSCRIPTION_LIST: '/subscription/get_subscription_list',
    GET_COMPANY_LIST: '/subscription/get_company_list',

    // Payment
    CREATE_PAYMENT_LINK: '/payment/create_payment_link',
    GET_PAYMENT_HISTORY: '/payment/get_payment_history',
    GET_ORDER_HISTORY: '/payment/get_order_history',

    // Post Letter
    SEND_MANUAL_POST_LETTER: '/post_letter/sendManualPostLetter',
    GET_POST_LETTER_HISTORY: '/post_letter/get_history',

    // Contact
    CONTACT_US: '/contact_us'
};

const httpMethod = {
    GET: 'get',
    POST: 'post'
}

export {
    LOCAL_STORAGE,
    PASSWORD_MINIMUM_LENGTH,
    VALIDATION_PATTERN,
    EMAIL_BLACKLIST,
    URL_VALIDATION_PATTERN,
    PRICE_LIST,
    WARNING_MESSAGE,
    NOTIFICATION_TYPE,
    SUBSCRIPTION_TYPE,
    apiEndpoint,
    httpMethod,
    DEFAULT_VALUES
}