// Import modules
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// Import environment
import { environment } from "src/environments/environment";
import { UtilService } from "src/app/services/util.service";
// Import constants
import { NOTIFICATION_TYPE, httpMethod } from "src/app/lib/constants";

@Injectable()
export class APIService {

  public APIBaseUrl = environment.APIBaseUrl;
  private Endpoint = environment.Endpoint;
  private version = environment.version;
  private baseURL;
  private headerOptions: any;

  constructor(public http: HttpClient, public utilService: UtilService) {
    this.baseURL = this.APIBaseUrl + this.Endpoint + this.version;
  }

  httpRequest(method: any, apiEndpoint: any, bodyData: any = {}, isToast: boolean = false, authToken: any = '') {
    return new Promise((resolve, reject) => {

      if (!authToken)
        authToken = this.utilService.getToken();

      this.headerOptions = {
        headers: new HttpHeaders().set('Authorization', authToken ? authToken : environment.publicAuthToken)
      }

      let httpOption: any;
      if (method === httpMethod.GET) {
        httpOption = this.http.get(this.baseURL + apiEndpoint, this.headerOptions)
      } else if (method === httpMethod.POST) {
        httpOption = this.http.post(this.baseURL + apiEndpoint, bodyData, this.headerOptions)
      } else {
        reject('Invalid Request');
      }

      httpOption.subscribe((res: any) => {
        if (isToast) {
          if (res.response_error)
            this.utilService.toastMessage(res.message, NOTIFICATION_TYPE.ERROR)
          else
            this.utilService.toastMessage(res.message, NOTIFICATION_TYPE.SUCCESS)
        }

        return resolve(res);
      }, (error: any) => {
        if (error?.status === 401 || error?.statusText === 'Unauthorized') {
          this.utilService.toastMessage('Please login to continue!', NOTIFICATION_TYPE.ERROR);
          this.utilService.logout();

          return reject(error.statusText);
        }

        return reject(error);
      })
    });
  }
}