<!-- Knowledge base Section -->
<div class="block-content">
  <div class="ng-star-inserted">
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div class="overflow-x-auto">
        <!-- Pricing Tier Message -->
        <div class="pricing-tier-message">
          <H3>{{pricingTierMessage}}</H3>
        </div>

        <!-- Pricing Tier Pick List Container -->
        <div style="margin-top: 150px;">
          <div class="pricing-container">
            <div class="col flex align-items-center justify-content-center">
              <p-splitter [style]="{ height: '300px' }" styleClass="mb-5">
                <ng-template pTemplate>
                  <div class="col flex align-items-center justify-content-center">
                    <p-pickList [source]="sourceAreas" [target]="targetAreas" sourceHeader="Available Areas"
                      targetHeader="Selected Areas" [dragdrop]="true" [responsive]="true"
                      [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px"
                      showSourceControls="false" showTargetControls="false" (onMoveToTarget)="onMove($event)"
                      (onMoveToSource)="onMove($event)" (onMoveAllToTarget)="onMoveAllToTarget($event, 'areas')"
                      (onMoveAllToSource)="onMoveAllToSource($event, 'areas')">
                      <ng-template let-item pTemplate="item">
                        <div class="flex flex-wrap p-2 align-items-center gap-3">
                          <span class="font-bold">{{item.name}}</span>
                        </div>
                      </ng-template>
                    </p-pickList>
                  </div>
                </ng-template>
                <ng-template pTemplate>
                  <div class="col flex align-items-center justify-content-center">
                    <p-pickList [source]="sourceSectors" [target]="targetSectors" sourceHeader="Available Sectors"
                      targetHeader="Selected Sectors" [dragdrop]="true" [responsive]="true"
                      [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px"
                      showSourceControls="false" showTargetControls="false" (onMoveToTarget)="onMove($event)"
                      (onMoveToSource)="onMove($event)" (onMoveAllToTarget)="onMoveAllToTarget($event, 'sectors')"
                      (onMoveAllToSource)="onMoveAllToSource($event, 'sectors')">
                      <ng-template let-item pTemplate="item">
                        <div class="flex flex-wrap p-2 align-items-center gap-3">
                          <span class="font-bold">{{item.name}}</span>
                        </div>
                      </ng-template>
                    </p-pickList>
                  </div>
                </ng-template>
              </p-splitter>
            </div>
            <p-toast></p-toast>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>