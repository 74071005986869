<!-- Post letter history Section -->
<div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <h2>Letter History</h2>
        <h5>Mail Posted: {{postLetterCount}} (30 Days)</h5>
      </div>
    </div>
    <div class="col-12 md:col-12 lg:col-9">
      <p-table #dt1 [value]="postLetters" dataKey="id" [loading]="loading" [rowHover]="true"
        styleClass="p-datatable-gridlines" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th id="companyName">Company Name</th>
            <th id="createdAt">Sent At</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-postLetter>
          <tr>
            <td>{{postLetter.companyDetail.CompanyName }}</td>
            <td>{{postLetter.createdAt | date: "MM-dd-yyyy HH:mm:ss" }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2" class="text-center">No record found.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="2" class="text-center">Loading data. Please wait.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>