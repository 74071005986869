<!-- Careers Section -->
<div class="container">
  <h2 class="text-center mt-5">Careers at Our Company</h2>
  <p class="text-center">
    Join our team and be part of an exciting journey. We are always looking for talented individuals who are passionate about innovation and growth.
  </p>
  <br/>
  <h3>Why Work With Us?</h3>
  <ul>
    <li>Dynamic and inclusive work environment</li>
    <li>Opportunities for professional growth and development</li>
    <li>Innovative projects and cutting-edge technology</li>
    <li>Work-life balance and flexible working hours</li>
    <li>Competitive salaries and comprehensive benefits</li>
  </ul>
  <br/>
  <h3>Our Values</h3>
  <ul>
    <li>Innovation: We strive to stay ahead in our field through constant creativity and ingenuity.</li>
    <li>Integrity: We value honesty and transparency in all our dealings.</li>
    <li>Collaboration: We believe in the power of teamwork and diverse perspectives.</li>
    <li>Customer Focus: We prioritize our customers' needs and satisfaction.</li>
    <li>Sustainability: We are committed to sustainable practices and reducing our environmental impact.</li>
  </ul>
  <br/>
  <p>
    Although we currently have no vacancies, we are always keen to meet energetic and talented professionals who would like to join our team. 
    If you wish to be considered for any future positions, please send your CV and cover letter to <a href="mailto:careers@ourcompany.com">careers&#64;ourcompany.com</a>.
  </p>
</div>