// Import modules
import { Component, OnInit } from "@angular/core";

// Define area/sector type
interface AreaOrSector {
  name: string;
}

@Component({
  selector: "app-knowledge-base",
  templateUrl: "./knowledge-base.component.html"
})
export class KnowledgeBaseComponent implements OnInit {

  sourceAreas: AreaOrSector[] = [];
  targetAreas: AreaOrSector[] = [];
  sourceSectors: AreaOrSector[] = [];
  targetSectors: AreaOrSector[] = [];

  pricingTierMessage: string = '';

  ngOnInit(): void {
    // Generate areas and sectors
    this.sourceAreas = Array.from({ length: 100 }, (_, i) => ({ name: `Area #${i + 1}` }));
    this.sourceSectors = Array.from({ length: 100 }, (_, i) => ({ name: `Sector #${i + 1}` }));

    this.updatePricingTierMessage(); // Initial call to set the message
  }

  onMove(event: any) {
    this.updatePricingTierMessage();
  }

  onMoveAllToTarget(event: any, type: string) {
    if (type === 'areas') {
      this.targetAreas = [...this.targetAreas, ...this.sourceAreas];
      this.sourceAreas = [];
    } else if (type === 'sectors') {
      this.targetSectors = [...this.targetSectors, ...this.sourceSectors];
      this.sourceSectors = [];
    }
    this.updatePricingTierMessage();
  }

  onMoveAllToSource(event: any, type: string) {
    if (type === 'areas') {
      this.sourceAreas = [...this.sourceAreas, ...this.targetAreas];
      this.targetAreas = [];
    } else if (type === 'sectors') {
      this.sourceSectors = [...this.sourceSectors, ...this.targetSectors];
      this.targetSectors = [];
    }
    this.updatePricingTierMessage();
  }

  updatePricingTierMessage() {
    const selectedAreas = this.targetAreas.length;
    const selectedSectors = this.targetSectors.length;

    if (selectedAreas === 0 && selectedSectors === 0) {
      this.pricingTierMessage = `Select the Areas and Sectors you would like to target and the correct tier you need will be displayed`;
    } else if (selectedAreas === 1 && selectedSectors === 0) {
      this.pricingTierMessage = `Based on the number of areas and sectors you want to target, the Free tier would be best as this allows you to target 1 area and 0 sectors.`;
    } else if (selectedAreas > 50 || selectedSectors > 5) {
      this.pricingTierMessage = `Based on the number of areas and sectors you want to target, the Platinum tier would be best as this allows you to target unlimited areas and unlimited sectors.`;
    } else if (selectedAreas > 20 || selectedSectors > 1) {
      this.pricingTierMessage = `Based on the number of areas and sectors you want to target, the Gold tier would be best as this allows you to target 50 areas and 5 sectors.`;
    } else if (selectedAreas > 5 || selectedSectors > 0) {
      this.pricingTierMessage = `Based on the number of areas and sectors you want to target, the Silver tier would be best as this allows you to target 20 areas and 1 sector.`;
    } else if (selectedAreas > 1 || selectedSectors === 0) {
      this.pricingTierMessage = `Based on the number of areas and sectors you want to target, the Bronze tier would be best as this allows you to target 5 areas and 0 sectors.`;
    }
  }
}