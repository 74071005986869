<!-- Pricing Section -->
<div class="block-content">
  <div class="ng-star-inserted">
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div class="overflow-x-auto">
        <table aria-label="pricing" class="w-full white-space-nowrap"
          style="border-collapse: collapse; border-spacing: 0;">
          <thead>
            <tr>
              <th id="pricing" class="surface-100 p-3">
                <div class="text-3xl text-900 mb-2">Pricing</div>
                <span class="text-600 line-height-3">Try Mailing companies near you for free!<br>Prices
                  are excluding VAT.</span>
              </th>
              <th id="free" class="surface-100 p-3 text-center">
                <div class="text-900 text-xl font-medium mb-3">Free</div>
                <div class="text-600 mb-3"><span class="text-3xl text-900">£0</span> /month</div>
                <button pButton pRipple type="button"
                  class="p-element p-ripple p-button-rounded p-button-outlined p-button p-component">
                  <span class="p-button-label">
                    <a [routerLink]="'/user/register'">Register</a>
                  </span>
                </button>
              </th>
              <th id="bronze" class="surface-100 p-3 text-center">
                <div class="text-900 text-xl font-medium mb-3">Bronze</div>
                <div class="text-600 mb-3"><span class="text-3xl text-900">£20</span> /month</div>
                <button pButton pRipple class="p-element p-ripple p-button-rounded p-button p-component"
                  (click)="createPaymentLink(PRICE_LIST.Bronze)" type="button">
                  <span class="p-button-label">Subscribe</span>
                </button>
              </th>
              <th id="silver" class="surface-100 p-3 text-center">
                <div class="text-900 text-xl font-medium mb-3">Silver</div>
                <div class="text-600 mb-3"><span class="text-3xl text-900">£100</span> /month</div>
                <button pButton pRipple class="p-element p-ripple p-button-rounded p-button p-component"
                  (click)="createPaymentLink(PRICE_LIST.Silver)" type="button"><span
                    class="p-button-label">Subscribe</span></button>
              </th>
              <th id="gold" class="surface-100 p-3 text-center">
                <div class="text-900 text-xl font-medium mb-3">Gold</div>
                <div class="text-600 mb-3"><span class="text-3xl text-900">£200</span> /month</div>
                <button pButton pRipple class="p-element p-ripple p-button-rounded p-button p-component"
                  (click)="createPaymentLink(PRICE_LIST.Gold)" type="button"><span
                    class="p-button-label">Subscribe</span></button>
              </th>
              <th id="platinum" class="surface-100 p-3 text-center">
                <div class="text-900 text-xl font-medium mb-3">Platinum</div>
                <div class="text-600 mb-3"><span class="text-3xl text-900">£500</span> /month</div>
                <button pButton pRipple class="p-element p-ripple p-button-rounded p-button p-component"
                  (click)="createPaymentLink(PRICE_LIST.Platinum)" type="button"><span
                    class="p-button-label">Subscribe</span></button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th id="areas" class="p-3 border-bottom-1 border-200">Areas</th>
              <td class="text-center border-bottom-1 border-200">1</td>
              <td class="text-center border-bottom-1 border-200">5</td>
              <td class="text-center border-bottom-1 border-200">20</td>
              <td class="text-center border-bottom-1 border-200">50</td>
              <td class="text-center border-bottom-1 border-200">Unlimited</td>
            </tr>
            <tr>
              <th id="sectors" class="p-3 border-bottom-1 border-200">Sectors</th>
              <td class="text-center border-bottom-1 border-200">0</td>
              <td class="text-center border-bottom-1 border-200">0</td>
              <td class="text-center border-bottom-1 border-200">1</td>
              <td class="text-center border-bottom-1 border-200">5</td>
              <td class="text-center border-bottom-1 border-200">Unlimited</td>
            </tr>
            <tr>
              <th id="-" class="p-3 border-bottom-1 border-200">-</th>
              <td class="text-center border-bottom-1 border-200" colspan="5">-</td>
            </tr>
            <tr>
              <th id="price-per-mail" class="p-3 border-bottom-1 border-200">
                Price per Mail (Single A4 Letter)
              </th>
              <td class="text-center border-bottom-1 border-200" colspan="5">£1</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>