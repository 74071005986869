// Import modules
import { Component, OnInit } from "@angular/core";
// Import constants
import { apiEndpoint, httpMethod } from "src/app/lib/constants";
// Import services
import { APIService } from "src/app/services/api.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html"
})
export class NotificationsComponent implements OnInit {

  public notifications: any[] = [];
  public loading: boolean = false;
  constructor(private apiService: APIService, private utilService: UtilService) { }

  ngOnInit(): void {
    this.getNotificationList();
  }

  getNotificationList() {
    this.loading = true;
    this.utilService.showSpinner();
    this.apiService.httpRequest(httpMethod.GET, apiEndpoint.GET_NOTIFICATION_LIST, {}, true).then((res: any) => {
      this.notifications = (res?.data?.length && !res.response_error) ? res.data : [];
      this.loading = false;
      this.utilService.hideSpinner();
    }).catch((error: any) => {
      this.utilService.handleErrorAndHideSpinner(error);
    });
  }
}