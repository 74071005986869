// Import modules
import { Component, OnInit } from "@angular/core";
// Import constants
import { apiEndpoint, httpMethod } from "src/app/lib/constants";
// Import services
import { APIService } from "src/app/services/api.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-post-letter-history",
  templateUrl: "./post-letter-history.component.html"
})
export class PostLetterHistoryComponent implements OnInit {

  public profileDetail: any = {};
  public postLetters: any[] = [];
  public postLetterCount: any;
  public loading: boolean = false;
  constructor(private apiService: APIService, private utilService: UtilService) {
  }

  ngOnInit(): void {
    const authToken = this.utilService.getToken();
    if (this.utilService.isEmpty(authToken))
      this.utilService.logout();

    const profileDetail: any = this.utilService.getLocalStorageData('profileDetail', 'string');
    this.profileDetail = JSON.parse(profileDetail);
    this.getPostLetterHistory();
  }

  getPostLetterHistory() {
    this.loading = true;
    this.utilService.showSpinner();
    this.postLetters = [];
    this.apiService.httpRequest(httpMethod.GET, apiEndpoint.GET_POST_LETTER_HISTORY, {}, true).then((res: any) => {
      this.postLetters = (res?.data?.postLetters?.length && !res.response_error) ? res.data.postLetters : [];
      this.postLetterCount = this.postLetters.length || 0;
      this.loading = false;
      this.utilService.hideSpinner();
    }).catch((error: any) => {
      this.utilService.handleErrorAndHideSpinner(error);
    });
  }
}