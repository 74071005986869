// Import modules
import { Component, OnInit } from "@angular/core";

// Import services
import { UtilService } from "src/app/services/util.service";
import { APIService } from "src/app/services/api.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrl: "./layout.component.scss"
})
export class LayoutComponent implements OnInit {

  public authToken: any;
  public APIBaseUrl: any;
  constructor(public utilService: UtilService, private apiService: APIService) {
    this.APIBaseUrl = apiService.APIBaseUrl;
  }

  ngOnInit(): void {
    this.getToken();
  }

  getToken() {
    this.authToken = this.utilService.getToken();
  }
}